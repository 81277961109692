import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  body: {
    paddingBottom: 60,
    paddingHorizontal: 26,
    fontFamily: "Cairo",
  },
  bodyEng: {
    paddingBottom: 60,
    paddingHorizontal: 26,
    fontFamily: "Inter",
  },
  firstHeader: {
    fontSize: 18,
    paddingTop: 38,
    paddingBottom: 7,
    fontFamily: "Cairo",
    fontWeight: 700,
  },
  otherPage: {
    fontSize: 10,
    paddingTop: 24,
  },
  date: {
    fontSize: 9.16369,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 30,
    textAlign: "right",
    color: "#adadad",
  },
  header: {
    fontSize: 12,
    marginTop: 24,
    marginBottom: 16.17,
    backgroundColor: "#3d8685",
    padding: "1px 4px",
    fontWeight: 600,
    color: "#FFF",
  },
  subHeading: {
    fontSize: 11,
    color: "#000",
    fontWeight: 700,
  },
  arabicSubHead: {
    fontSize: 11,
    color: "#000",
    fontWeight: 700,
    textAlign: "right",
  },
  arabicText: {
    fontSize: 11,
    marginTop: 10,
    textAlign: "right",
  },
  text: {
    fontSize: 11,
    marginTop: 10,
  },
  imageContainer: {
    marginVertical: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  image: {
    width: "170px",
    height: "120px",
  },
  tblHeading: {
    fontSize: "11.2325px",
    marginBottom: 16.65,
    marginTop: 17.17,
  },
  arabicTblHeading: {
    fontSize: "11.2325px",
    marginBottom: 16.65,
    marginTop: 17.17,
    textAlign: "right",
  },
  tblHeader: {
    textAlign: "center",
    flexDirection: "row",
    fontWeight: 700,
    color: "#000",
    marginBottom: 5,
  },
  tblRow: {
    display: "flex",
    flexDirection: "row",
  },
  tblCell: {
    backgroundColor: "#F4F4F4",
    paddingVertical: 10,
    marginVertical: 0,
    marginHorizontal: 8,
    textAlign: "center",
    flex: 0.25,
    fontSize: "10.3214px",
  },
  tableTextH: {
    fontSize: 11.2469,
    flex: 0.25,
    textAlign: "center",
  },
  section: {
    paddingBottom: 5,
  },
  sectionEng: {
    paddingVertical: 20,
  },
  subSection: {
    paddingVertical: 5,
  },
  subSectionHeading: {
    marginTop: 7,
    marginBottom: 9.26,
    paddingHorizontal: 6,
    fontSize: 10.3214,
    fontWeight: 700,
  },
  tblRowSentiment: {
    backgroundColor: "#F4F4F4",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 10.3214,
    marginVertical: 4,
    borderRadius: 8,
    paddingVertical: 2,
  },
  smallSentiment: {
    width: 30,
    height: 30,
  },
  lineGraphContainer: {
    marginTop: 30,
  },
  lineGraph: {
    width: 530,
    height: 150.97,
  },
  lineGraphHead: {
    marginBottom: 30,
    fontWeight: 700,
    fontSize: 10.3542,
  },
  sentimentImageContainer: {
    flex: 0.25,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  sentimentCellContainer: {
    flex: 0.25,
    textAlign: "center",
  },
});
