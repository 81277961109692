import { useState } from "react";
import { Modal } from "react-bootstrap";
import { BasicPie } from "@components/Chart/index";
import "./index.scss";

const KEYS = ["Positive", "Negative", "Neutral"];

interface Documents {
  title?: string;
  documents?: any;
}

//Interactive Table In Modal
export default function InteractiveTable(props: any) {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<Partial<Documents>>({});
  const handleModal = (index: number, data: any) => {
    if (props.headers[index] === "Documents") {
      const { title, documents } = data;
      if (title && documents) {
        setModalData({ title, documents });
        setOpen(true);
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const checkKeys = (keys: any, data: any) => {
    for (let key in data) {
      if (keys.includes(key)) {
        return false;
      }
    }

    return true;
  };
  return (
    <>
      <div className={`report-table`}>
        <div className="report-table-sub_head">{props.title}</div>

        <div className="t-main">
          <div className="t-head">
            {props.headers.map((item: any, index: number) => (
              <div className="t-cell" key={`interactive-head-${index}`}>
                {item}
              </div>
            ))}
          </div>

          <div className="t-body">
            {props?.body?.map((item: any, index: number) => (
              <div className="t-row" key={`interactive-row-${index}`}>
                {item.map((data: any, i: number) => {
                  return (
                    <div
                      className={`t-cell ${
                        props.headers[i] === "Documents" && "t-cell-link"
                      }`}
                      onClick={() => {
                        return handleModal(i, {
                          title: item[0],
                          documents: props.documents[index],
                        });
                      }}
                      key={`interactive-inner-row-${i}`}
                    >
                      {checkKeys(KEYS, data) ? (
                        data
                      ) : (
                        <BasicPie chartData={data} />
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal show={open} onHide={handleClose} className="interceptive-modal">
        <div className="interceptive-modal-content">
          <div className="interceptive-modal-content_title">
            <em>“{modalData?.title}”</em> has been mention in the following
            articles:
          </div>
          <div className="interceptive-modal-content_container">
            {modalData?.documents?.map((item: any, i: any) => (
              <div
                className="interceptive-modal-content_card"
                key={`interactive-modal-${i}`}
              >
                <div className="card_index">{i + 1}</div>
                <div>
                  <p className="card_title">{item.title}</p>
                  <div
                    onClick={() => window.open(item.source, "_blank")}
                    className="card_link"
                  >
                    <a>{item.source}</a>
                  </div>
                  <p className="card_status">{item.sentiment}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
}
