import air_quality from "@assets/public-demo-img/air-quality.jpg";
import learning from "@assets/public-demo-img/e-learning.jpg";
import apartment from "@assets/public-demo-img/apartment.jpg";
import land from "@assets/public-demo-img/land-prices.jpg";
import saudiMoney from "@assets/public-demo-img/saudimoney.jpg";
import schools from "@assets/public-demo-img/schools.jpg";

export const cards = [
  {
    title: "Saudi Climate",
    img_url: air_quality,
    desc: "Public perception on Saudi’s  climate",
    source: "News | Social Media",
    results: 18190,
    fill: true,
  },
  {
    title: "Public-Sector Salaries",
    img_url: saudiMoney,

    desc: "Public perception on public-sector salaries.",
    source: "News | Social Media",
    results: 10364,
    fill: false,
  },
  {
    title: "Riyadh Apartments",
    img_url: apartment,

    desc: "Public perception on Riyadh apartments.",
    source: "Emails | Social Media",
    results: 6730,
    fill: true,
  },
  {
    title: "Education in Covid-19",
    img_url: learning,

    desc: "Public perception on education during Covid-19.",
    source: "News | Social Media | Emails",
    results: 5621,
    fill: true,
  },
  {
    title: "Non-Arabic Schools",
    img_url: schools,

    source: "Social Media",
    desc: "Public Perception on non-Arabic Schools.",
    results: 1267,
    fill: false,
  },
  {
    title: "Land Prices",
    img_url: land,

    source: "News | Social Media | Emails",
    desc: "Public perception on Land prices",
    results: 23102,
    fill: false,
  },
];

export const cardsArabic = [
  {
    title: "جودة الهواء",
    img_url: air_quality,

    desc: "تصور الجمهور لجودة الهواء في السعودية",
    source: "الأخبار | وسائل التواصل الاجتماعي",
    results: 18190,
    fill: true,
  },
  {
    title: "رواتب القطاع العام",
    img_url: saudiMoney,

    desc: "تصور الجمهور لرواتب القطاع العام",
    source: "الأخبار | وسائل التواصل الاجتماعي",
    results: 10364,
    fill: false,
  },
  {
    title: "شقق الرياض",
    img_url: apartment,

    desc: "تصور الجمهور عن شقق الرياض.",
    source: "رسائل البريد الإلكتروني | وسائل التواصل الاجتماعي",
    results: 6730,
    fill: true,
  },
  {
    title: "التعليم في Covid-19",
    img_url: learning,

    desc: "التصور العام للتعليم خلال Covid-19.",
    source: "الأخبار | وسائل الاعلام الاجتماعية | رسائل البريد الإلكتروني",
    results: 5621,
    fill: true,
  },
  {
    title: "المدارس غير العربية",
    img_url: schools,

    source: "وسائل التواصل الاجتماعي",
    desc: "التصور العام للمدارس غير العربية.",
    results: 1267,
    fill: false,
  },
  {
    title: "أسعار الأرض",
    img_url: land,

    source: "الأخبار | وسائل الاعلام الاجتماعية | رسائل البريد الإلكتروني",
    desc: "تصور الجمهور لأسعار الأراضي",
    results: 23102,
    fill: false,
  },
];

export const socialSources: any = {
  en: [
    {
      title: "Henrik Jansson",
      desc: "Antiitet. Gamification Persiv. Primafili. Anavi.Tempometer.",
      img: "qoura",
    },
    {
      title: "Alexander Johnsson",
      desc: "Antiitet. Gamification Persiv. Primafili. Anavi.Tempometer.",
      img: "reddit",
    },
    {
      title: "Yvonne Sjögren",
      desc: "Antiitet. Gamification Persiv. Primafili. Anavi.Tempometer.",
      img: "linkedin",
    },
    {
      title: "Hans Lindholm",
      desc: "Antiitet. Gamification Persiv. Primafili. Anavi.Tempometer.",
      img: "twitter",
    },
  ],
  ar: [
    {
      title: "جريدة الجزيرة",
      desc: "لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج ",
      img: "qoura",
    },
    {
      title: "خالد عبدالرحمن",
      desc: "لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج ",
      img: "reddit",
    },
    {
      title: "جريدة الرياض",
      desc: " أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار ",
      img: "linkedin",
    },
    {
      title: "جريدة الرياض",
      desc: "كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور",
      img: "linkedin",
    },
  ],
};

export const topLinks: any = {
  en: [
    {
      title: "Töde kvasipatologi. ",
      desc: "Personalisering. Pinterest. Delig. Investering. Malaligt. USP. Konvertering. Soft land Bergman. LinkedIn. BOPIS. Megal. Content provider. Orände. Syrade. Robot retail. Ding. Bootstrapping. ",
      url: "www.Facilitera-Metataggar-Innovationshöjd.com",
    },
    {
      title: "Töde kvasipatologi. ",
      desc: "Personalisering. Pinterest. Delig. Investering. Malaligt. USP. Konvertering. Soft land Bergman. LinkedIn. BOPIS. Megal. Content provider. Orände. Syrade. Robot retail. Ding. Bootstrapping. ",
      url: "www.Degraf. Pneumaism. Min. Innovationssystem. Ingemar Åström. Plak. Terrafili. com",
    },
    {
      title: "BBC News",
      desc: "Personalisering. Pinterest. Delig. Investering. Malaligt. USP. Konvertering. Soft land Bergman. LinkedIn. BOPIS. Megal. Content provider. Orände. Syrade. Robot retail. Ding. Bootstrapping. ",
      url: "www.Degraf. Pneumaism. Min. Innovationssystem. Ingemar Åström. Plak. Terrafili. com",
    },
  ],
  ar: [
    {
      title: "جريدة الجزيرة",
      desc: "إضفاء الطابع الشخصي. بينتيريست. ديليج. الاستثمار. مالاليجت. USP. كونفيرتر. الأرض اللينة بيرجمان. ينكدين. بوبيس. ميغال. مزود المحتوى. أوراندي. سيريد. روبوت للبيع بالتجزئة. دينغ. التمهيد.",
      url: "www.Facilitera-Metataggar-Innovationshöjd.com ",
    },
    {
      title: "جريدة عكاظ",
      desc: "إضفاء الطابع الشخصي. بينتيريست. ديليج. الاستثمار. مالاليجت. USP. كونفيرتر. الأرض اللينة بيرجمان. ينكدين. بوبيس. ميغال. مزود المحتوى. أوراندي. سيريد. روبوت للبيع بالتجزئة. دينغ. التمهيد.",
      url: "www.Degraf. Pneumaism. Min. Innovationssystem. Ingemar Åström. Plak. Terrafili. com",
    },
    {
      title: "جريدة الرياض",
      desc: "إضفاء الطابع الشخصي. بينتيريست. ديليج. الاستثمار. مالاليجت. USP. كونفيرتر. الأرض اللينة بيرجمان. ينكدين. بوبيس. ميغال. مزود المحتوى. أوراندي. سيريد. روبوت للبيع بالتجزئة. دينغ. التمهيد.",
      url: "www.Jomån. Desbel. Pobebade. Snapchat. Eberir. Nisat. Blockchain.com",
    },
  ],
};

export const filterArabicMock = [
  {
    label: "Real Estate",
    data: ["أراضي", "الإسكان"],
  },
  {
    label: "Economy",
    data: ["المرتبات", "أسعار المواد الغذائية"],
  },
  {
    label: "Environment",
    data: ["مناخ", "التصحر", "جفاف"],
  },
  {
    label: "Education",
    data: ["أسعار التعليم", "القطاعات المدرسية", "قضايا المدرسة"],
  },
];

export const filterMock = [
  {
    label: "Real Estate",
    data: ["Lands", "Housing"],
  },
  {
    label: "Economy",
    data: ["Salaries", "Food prices"],
  },
  {
    label: "Environment",
    data: ["Climate", "Desertification", "Drought"],
  },
  {
    label: "Education",
    data: ["Education Prices", "School Sectors", "School Issues"],
  },
];

const wordCloud: any = {
  en: [
    {
      text: "Såbel",
      value: 65,
    },

    {
      text: "Röfägen",
      value: 64,
    },
    {
      text: "Dihägt",
      value: 65,
    },
    {
      text: "Krossa",
      value: 66,
    },
    {
      text: "Sobinde",
      value: 65,
    },
    {
      text: "Nelans",
      value: 65,
    },
    {
      text: "Vant",
      value: 65,
    },
    {
      text: "Reneskapet",
      value: 65,
    },
    {
      text: "Rosäs",
      value: 65,
    },
    {
      text: "Unicorn",
      value: 65,
    },

    {
      text: "Vanera",
      value: 65,
    },

    {
      text: "Köd",
      value: 65,
    },

    {
      text: "Infralig",
      value: 65,
    },
  ],
  ar: [
    {
      text: "سابيل",
      value: 65,
    },

    {
      text: "روفجن",
      value: 64,
    },
    {
      text: "ديهاغت",
      value: 65,
    },
    {
      text: "كروسا",
      value: 66,
    },
    {
      text: "سوبيندي",
      value: 65,
    },
    {
      text: "نيلانز",
      value: 65,
    },
    {
      text: "فانت",
      value: 65,
    },
    {
      text: "رينسكابت",
      value: 65,
    },
    {
      text: "روزاس",
      value: 65,
    },
    {
      text: "وحيد القرن",
      value: 65,
    },

    {
      text: "فانيرا",
      value: 65,
    },

    {
      text: "كود",
      value: 65,
    },

    {
      text: "إنفرليج",
      value: 65,
    },
  ],
};

export default wordCloud;
