import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

//Senitment Analysis Chart Render
export default function AnalysisChart({
  colors = ["#4727CB", "#C2C6CB", "#B8356C"],
  data,
  labels,
}: any) {
  let chartData = [];
  let i = 0;
  if (data) {
    for (let key in data) {
      chartData.push({
        name: labels[i],
        negative: data[key].Negative,
        neutral: data[key].Neutral,
        positive: data[key].Positive,
      });
      i++;
    }
  }

  return (
    <div className={`sentiment-chart english`}>
      <h2>Sentiment analysis across time</h2>
      <div className="chart-render-container">
        <ResponsiveContainer>
          <AreaChart
            className="chart-container"
            data={chartData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" fontSize={12} />
            <YAxis fontSize={12} />
            <Tooltip />
            <Area
              dataKey="negative"
              stackId="1"
              stroke={colors[2]}
              fill={colors[2]}
              fillOpacity={1}
            />
            <Area
              dataKey="neutral"
              stackId="1"
              stroke={colors[1]}
              fill={colors[1]}
              fillOpacity={1}
            />
            <Area
              dataKey="positive"
              stackId="1"
              stroke={colors[0]}
              fill={colors[0]}
              fillOpacity={1}
            />
            <Legend
              align="left"
              payload={[
                {
                  id: "positive",
                  value: "Positive",
                  type: "square",
                  color: colors[0],
                },
                {
                  id: "negative",
                  value: "Negative",
                  type: "square",
                  color: colors[2],
                },
                {
                  id: "neutral",
                  value: "Neutral",
                  type: "square",
                  color: colors[1],
                },
              ]}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
