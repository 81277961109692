import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./index.scss";

//Search Field At
export default function Search({ onChange }: any) {
  const [text, setText] = useState("");
  const { pathname } = useLocation();
  const isDashboard = pathname.split("/").pop() === "dashboard";

  const { rtl } = useSelector((state: any) => state.utils);

  const placeholder = rtl === "ar" && !isDashboard ? "ابحث هنا" : "Search here";

  const changeHandler = (e: any) => {
    setText(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <input
      value={text}
      className={`search-input ${
        !isDashboard ? rtl === "ar" && "change-align" : "english"
      }`}
      placeholder={placeholder}
      onChange={changeHandler}
    />
  );
}
