import { useTranslation } from "react-i18next";
import DashboardLayout from "@components/Layout";
import Card from "@components/Card";
import { cards, cardsArabic } from "../utils/mock";

export default function Dashboard() {
  const { i18n } = useTranslation();
  const col_resp = "row row-cols-md-3 row-cols-sm-4  ";

  return (
    <DashboardLayout>
      <div className={`${col_resp}  topics h-100 m-0`}>
        {
          // i18n.language === "en"
          //   ?
          cards.map((item, i) => (
            <div
              key={i}
              className={`cards col ${i >= 4 && i <= 6 && "mb-0 english"}`}
            >
              <Card data={item} i={i} />
            </div>
          ))
          // : cardsArabic.map((item, i) => (
          //     <div
          //       key={i}
          //       className={`cards col ${
          //         i >= 4 && i <= 6 && "mb-0"
          //       } change-align`}
          //     >
          //       <Card data={item} i={i} />
          //     </div>
          //   ))
        }
      </div>
    </DashboardLayout>
  );
}
