import { useNavigate } from "react-router";

import Sources from "@assets/Icon/Sources";
import HeartFill from "@assets/Icon/HeartFill";
import "./index.scss";
import Heart from "@assets/Icon/Heart";

export default function Card({ data, i }: any) {
  const navigate = useNavigate();

  function handleNavigation() {
    if (i < 1) navigate("/dashboard/results");
  }
  return (
    <div
      className={`card card-container shadow-sm ${i > 0 && "card-disabled"}`}
      onClick={handleNavigation}
      style={{ backgroundImage: `url(${data.img_url})` }}
    >
      <div className="card-img-overlay card-container-gradient">
        <div>
          <h2 className="card-container-title">{data?.title}</h2>
          <p>{data?.desc}</p>
          <span>{data?.results} Result</span>
        </div>
        <div className="card-container-social">
          <span>
            <Sources />
            <span className="source">{data?.source}</span>
          </span>
          <span>{data?.fill ? <HeartFill /> : <Heart />}</span>
        </div>
      </div>
    </div>
  );
}
