export default function TwitterIcon() {
  return (
    <svg
      width={29}
      height={29}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 14.5C0 6.49187 6.49187 0 14.5 0C22.5081 0 29 6.49187 29 14.5C29 22.5081 22.5081 29 14.5 29C6.49187 29 0 22.5081 0 14.5Z"
        fill="url(#paint0_linear_809_814)"
      />
      <path
        d="M0 14.5C0 6.49187 6.49187 0 14.5 0C22.5081 0 29 6.49187 29 14.5C29 22.5081 22.5081 29 14.5 29C6.49187 29 0 22.5081 0 14.5Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M14.8157 12.0858L14.8462 12.5875L14.339 12.5261C12.4931 12.2906 10.8805 11.4919 9.51122 10.1505L8.84182 9.48496L8.6694 9.97646C8.30427 11.0721 8.53755 12.2291 9.29823 13.0073C9.70393 13.4374 9.61265 13.4988 8.91282 13.2428C8.6694 13.1609 8.45641 13.0995 8.43613 13.1302C8.36513 13.2019 8.60855 14.1337 8.80125 14.5023C9.06496 15.0143 9.60251 15.516 10.1908 15.8129L10.6877 16.0484L10.0995 16.0587C9.53151 16.0587 9.51122 16.0689 9.57208 16.284C9.77493 16.9495 10.5762 17.656 11.4687 17.9632L12.0975 18.1783L11.5499 18.5059C10.7385 18.9769 9.78507 19.2432 8.83168 19.2636C8.37527 19.2739 8 19.3148 8 19.3455C8 19.4479 9.23738 20.0214 9.95749 20.2466C12.1178 20.9122 14.6839 20.6255 16.6109 19.4889C17.9802 18.68 19.3494 17.0724 19.9884 15.516C20.3332 14.6866 20.6781 13.1712 20.6781 12.4442C20.6781 11.9731 20.7085 11.9117 21.2765 11.3485C21.6112 11.0209 21.9256 10.6625 21.9864 10.5601C22.0879 10.3656 22.0777 10.3656 21.5605 10.5396C20.6983 10.8468 20.5766 10.8059 21.0026 10.3451C21.317 10.0174 21.6923 9.42353 21.6923 9.24946C21.6923 9.21874 21.5402 9.26993 21.3677 9.36209C21.1852 9.46448 20.7795 9.61808 20.4752 9.71023L19.9275 9.8843L19.4305 9.5464C19.1567 9.36209 18.7713 9.1573 18.5684 9.09586C18.0512 8.95251 17.2601 8.97299 16.7935 9.13682C15.5257 9.5976 14.7244 10.7854 14.8157 12.0858Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_809_814"
          x1="14.7959"
          y1="-3.84653"
          x2="36.3332"
          y2="24.9619"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.578125" stopColor="#4731A0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
