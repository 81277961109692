
export const basicInfo = {
    time: '1:04 PM',
    date: 'Today 4/6/2022',
    overview: `
          <p className="report-modal-desc">
            From 2020 to Q1 2022, 5 data sources were analyzed (2,000,000
            documents) with 3,000 documents related to Saudi Climate.
          <p className="report-modal-desc">
            The overall sentiment of Saudi Climate from Arabic news is %60
            positive, %10 negative and %30 neutral.
          </p>
    `,
    quote: 'Gel pebubel. Fevis precentrism. Vavåligen hyperfoni. Relaterade artiklar. Heterosyktigt Ydåssade postvision.',
    author: 'Gel pebubel'
}

const perceptionData = [
    {
        key: "positive",
        title: "Positive Perception",
        desc: `1,800 documents had positive public perception on Saudi Climate,
      these articles had mentioned following persons: Khaled Ahmed, Saad
      Misfer and Tariq Mohammad and mentioned the following
      organizations: Ministry of Water, Ministry of Rural Affairs and
      Center of Environmental Control.
      
      Moreover, positive public perception on Saudi Climate, mentioned the
      following events Green initiative, Global Environment forum and
      G20 Summit. Furthermore, Saudi Climate had been associated with the
      following positive mentions: Carbon Neutrality, +20 million trees
      and +200 Saudi Climate centers.`,
        table: {
            title: "Positive Summary (1,800 documents)",
            headers: ["People", "Organizations", "Events", "Extraction"],
            body: [
                [
                    "Khalid Ahmed",
                    "Ministry of Water",
                    "Green Initiative",
                    "Carbon Neutrality",
                ],
                [
                    "Saad Misfer",
                    "Ministry of Water",
                    "Global Env. Forum",
                    "+20 m trees",
                ],
                [
                    "Tariq Mohammed",
                    "Ministry of Water",
                    "G20 Summit",
                    "+200 Saudi Climate centers",
                ],
            ],
        },
    },
    {
        key: "negative",
        title: "Negative Perception",
        desc: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia dignissimos molestiae, ipsam sint aliquam ratione hic maxime eum, aperiam laborum perspiciatis! Rerum ipsam laborum iste vero voluptate, accusamus culpa consectetur?`,
        table: {
            title: "Negative Summary (1,800 documents)",
            headers: ["People", "Organizations", "Events", "Extraction"],
            body: [
                [
                    "Khalid Ahmed",
                    "Ministry of Water",
                    "Green Initiative",
                    "Carbon Neutrality",
                ],
            ],
        },
    },
    {
        key: "neutral",
        title: "Neutral Perception",
        desc: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia dignissimos molestiae, ipsam sint aliquam ratione hic maxime eum, aperiam laborum perspiciatis! Rerum ipsam laborum iste vero voluptate, accusamus culpa consectetur?`,
        table: {
            title: "Neutral Summary (1,800 documents)",
            headers: ["People", "Organizations", "Events", "Extraction"],
            body: [
                [
                    "Khalid Ahmed",
                    "Ministry of Water",
                    "Green Initiative",
                    "Carbon Neutrality",
                ],
                [
                    "Khalid Ahmed",
                    "Ministry of Water",
                    "Global Env. Forum",
                    "+20 m trees",
                ],
            ],
        },
    },
];

const interactiveData = [
    {
        key: "people",
        title: "People",
        table: {
            headers: ["Aspects", "Mentions", "Articles", "Sentiment"],
            body: [
                ["Aspects 1", 84, 26, "Sentiment"],
                ["Aspects 2", 84, 26, "Sentiment"],
                ["Aspects 3", 84, 26, "Sentiment"],
                ["Aspects 4", 84, 26, "Sentiment"],
            ],
        },
    },
    {
        key: "organization",
        title: "Organizations",
        table: {
            headers: ["Aspects", "Mentions", "Articles", "Sentiment"],
            body: [["Aspects 1", 84, 26, "Sentiment"]],
        },
    },
    {
        key: "event",
        title: "Events",
        table: {
            headers: ["Aspects", "Mentions", "Articles", "Sentiment"],
            body: [
                ["Aspects 1", 84, 26, "Sentiment"],
                ["Aspects 2", 84, 26, "Sentiment"],
            ],
        },
    },
    {
        key: "location",
        title: "Locations",
        table: {
            headers: ["Aspects", "Mentions", "Articles", "Sentiment"],
            body: [
                ["Location 1", 84, 26, "Sentiment"],
                ["Location 2", 84, 26, "Sentiment"],
                ["Location 3", 84, 26, "Sentiment"],
            ],
        },
    },
    {
        key: "key",
        title: "Key phrases",
        table: {
            headers: ["Aspects", "Mentions", "Articles", "Sentiment"],
            body: [
                ["Aspects 1", 84, 26, "Sentiment"],
                ["Aspects 2", 84, 26, "Sentiment"],
                ["Aspects 3", 84, 26, "Sentiment"],
                ["Aspects 4", 84, 26, "Sentiment"],
                ["Aspects 5", 84, 26, "Sentiment"],
            ],
        },
    },
];

const interactiveData_arabic = [
    {
        key: "people",
        title: "الناس",
        table: {
            headers: ["النواحي", "يذكر", "مقالات", "المشاعر"],
            body: [
                ["النواحي 1", '٨٤', '٢٦', "Sentiment"],
                ["النواحي 2", '٨٤', '٢٦', "Sentiment"],
                ["النواحي 3", '٨٤', '٢٦', "Sentiment"],
                ["النواحي 4", '٨٤', '٢٦', "Sentiment"],
            ],
        },
    },
    {
        key: "organization",
        title: "المنظمات",
        table: {
            headers: ["النواحي", "يذكر", "مقالات", "المشاعر"],
            body: [["النواحي 1", '٨٤', '٢٦', "Sentiment"]],
        },
    },
    {
        key: "event",
        title: "الأحداث",
        table: {
            headers: ["النواحي", "يذكر", "مقالات", "المشاعر"],
            body: [
                ["النواحي 1", '٨٤', '٢٦', "Sentiment"],
                ["النواحي 2", '٨٤', '٢٦', "Sentiment"],
            ],
        },
    },
    {
        key: "location",
        title: "المواقع",
        table: {
            headers: ["النواحي", "يذكر", "مقالات", "المشاعر"],
            body: [
                ["موقع 1", '٨٤', '٢٦', "Sentiment"],
                ["موقع 2", '٨٤', '٢٦', "Sentiment"],
                ["موقع 3", '٨٤', '٢٦', "Sentiment"],
            ],
        },
    },
    {
        key: "key",
        title: "العبارات الرئيسية",
        table: {
            headers: ["النواحي", "يذكر", "مقالات", "المشاعر"],
            body: [
                ["النواحي 1", '٨٤', '٢٦', "Sentiment"],
                ["النواحي 2", '٨٤', '٢٦', "Sentiment"],
                ["النواحي 3", '٨٤', '٢٦', "Sentiment"],
                ["النواحي 4", '٨٤', '٢٦', "Sentiment"],
                ["النواحي 5", '٨٤', '٢٦', "Sentiment"],
            ],
        },
    },
];

const basicInfo_arabic = {
    time: '1:04 PM',
    date: 'Today 4/6/2022',
    overview: `
          <p className="report-modal-desc">
          من عام 2020 إلى الربع الأول من عام 2022 ، تم تحليل 5 مصادر بيانات (2،000،000
            وثائق) مع 3000 وثيقة تتعلق بجودة الهواء.
          <p className="report-modal-desc">
          كانت المشاعر العامة تجاه جودة الهواء في الأخبار العربية 60٪ إيجابية و 10٪ سلبية و 30٪ حيادية.
          </p>
    `,
    quote: 'جل بيبوبيل. مداخلة فيفيس. فافاليغن هايبرفوني. مقالة ريلاتيرادي. Heterosyktigt Ydåssade postvision.',
    author: 'جل بيبوبيل'
}

const perceptionArabic = [
    {
        key: "positive",
        title: "تصور إيجابي",
        desc: `تحتوي 1800 وثيقة على تصور عام إيجابي حول جودة الهواء ،
        ذكرت هذه المقالات الأشخاص التالية أسماؤهم: خالد أحمد ، سعد
        مسفر وطارق محمد وذكر ما يلي
        المنظمات: وزارة المياه ، وزارة الشؤون الريفية و
        مركز مراقبة البيئة.
        
        علاوة على ذلك ، فإن التصور العام الإيجابي لنوعية الهواء ، ذكر
        الأحداث التالية المبادرة الخضراء ، منتدى البيئة العالمية و
        قمة مجموعة العشرين. علاوة على ذلك ، ارتبطت جودة الهواء بـ
        بعد الإشارات الإيجابية: معادلة الكربون ، +20 مليون شجرة
        و +200 مركز جودة الهواء.`,
        table: {
            title: "ملخص إيجابي (1800 وثيقة)",
            headers: ["الناس", "المنظمات", "الأحداث", "اِستِخلاص"],
            body: [
                [
                    "خالد احمد",
                    "وزارة المياه",
                    "المبادرة الخضراء",
                    "حياد الكربون",
                ],
                [
                    "سعد مسفر",
                    "وزارة المياه",
                    "العالمية Env. المنتدى",
                    "+20 م شجرة",
                ],
                [
                    "طارق محمد",
                    "وزارة المياه",
                    "قمة مجموعة العشرين",
                    "+200 مركز جودة هواء",
                ],
            ],
        },
    },
    {
        key: "negative",
        title: "تصور سلبي",
        desc: `تحتوي 1800 وثيقة على تصور عام إيجابي حول جودة الهواء ،
        ذكرت هذه المقالات الأشخاص التالية أسماؤهم: خالد أحمد ، سعد
        مسفر وطارق محمد وذكر ما يلي
        المنظمات: وزارة المياه ، وزارة الشؤون الريفية و
        مركز مراقبة البيئة.
        
        علاوة على ذلك ، فإن التصور العام الإيجابي لنوعية الهواء ، ذكر
        الأحداث التالية المبادرة الخضراء ، منتدى البيئة العالمية و
        قمة مجموعة العشرين. علاوة على ذلك ، ارتبطت جودة الهواء بـ
        بعد الإشارات الإيجابية: معادلة الكربون ، +20 مليون شجرة
        و +200 مركز جودة الهواء.`,
        table: {
            title: "ملخص سلبي (1800 مستند)",
            headers: ["الناس", "المنظمات", "الأحداث", "اِستِخلاص"],
            body: [
                [
                    "خالد احمد",
                    "وزارة المياه",
                    "المبادرة الخضراء",
                    "حياد الكربون",
                ]
            ],
        },
    },
    {
        key: "neutral",
        title: "تصور محايد",
        desc: `تحتوي 1800 وثيقة على تصور عام إيجابي حول جودة الهواء ،
        ذكرت هذه المقالات الأشخاص التالية أسماؤهم: خالد أحمد ، سعد
        مسفر وطارق محمد وذكر ما يلي
        المنظمات: وزارة المياه ، وزارة الشؤون الريفية و
        مركز مراقبة البيئة.
        
        علاوة على ذلك ، فإن التصور العام الإيجابي لنوعية الهواء ، ذكر
        الأحداث التالية المبادرة الخضراء ، منتدى البيئة العالمية و
        قمة مجموعة العشرين. علاوة على ذلك ، ارتبطت جودة الهواء بـ
        بعد الإشارات الإيجابية: معادلة الكربون ، +20 مليون شجرة
        و +200 مركز جودة الهواء.`,
        table: {
            title: "ملخص محايد (1800 مستند)",
            headers: ["الناس", "المنظمات", "الأحداث", "اِستِخلاص"],
            body: [
                [
                    "خالد احمد",
                    "وزارة المياه",
                    "المبادرة الخضراء",
                    "حياد الكربون",
                ],
                [
                    "سعد مسفر",
                    "وزارة المياه",
                    "العالمية Env. المنتدى",
                    "+20 م شجرة",
                ],
            ],
        },
    },
]



export const reportTabs: any = [
    {
        title: 'Local News',
        key: 'local_news',
        overview: 'Overview',
    },
    {
        title: 'Global News',
        overview: 'Overview',
        key: 'global_news',
    },
    {
        title: 'Local Social Media',
        overview: 'Overview',
        key: 'local_social',
    },
    {
        title: 'Global Social Media',
        overview: 'Overview',
        key: 'global_social',
    },
]
