export default function Sources() {
  return (
    <svg
      width="13"
      height="17"
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8334 6.68265C12.4742 6.96688 12.0815 7.2078 11.6864 7.40535C10.2284 8.13436 8.36211 8.5 6.5 8.5C4.63789 8.5 2.7716 8.13436 1.31357 7.40535C0.918491 7.20781 0.525876 6.9669 0.166687 6.68269V8.50803C0.170913 9.34234 0.833946 10.1453 2.02166 10.7392C3.20937 11.333 4.82026 11.6667 6.5 11.6667C8.17974 11.6667 9.79064 11.333 10.9783 10.7392C12.1699 10.1434 12.8333 9.33719 12.8333 8.5H12.8334V6.68265ZM12.8334 11.4327C12.4742 11.7169 12.0815 11.9578 11.6864 12.1553C10.2284 12.8844 8.36211 13.25 6.5 13.25C4.63789 13.25 2.7716 12.8844 1.31357 12.1553C0.918491 11.9578 0.525876 11.7169 0.166687 11.4327V13.25V13.25C0.166687 14.9989 3.00222 16.4167 6.50002 16.4167C9.99782 16.4167 12.8334 14.9989 12.8334 13.25V13.25V11.4327ZM0.166687 3.75803C0.170913 4.59234 0.833946 5.39532 2.02166 5.98917C3.20937 6.58302 4.82026 6.91667 6.5 6.91667C8.17974 6.91667 9.79064 6.58302 10.9783 5.98917C12.1699 5.39341 12.8333 4.58719 12.8333 3.75H12.8334C12.8333 2.0011 9.99782 0.583344 6.50002 0.583344C3.00223 0.583344 0.166701 2.0011 0.16669 3.75H0.166687V3.75803Z"
        fill="#281964"
      />
    </svg>
  );
}
