import ReactWordcloud from "react-wordcloud";
import { useTranslation } from "react-i18next";
import { Resizable } from "re-resizable";
import i18next from "i18next";
import "./index.scss";

const resizeStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
};

//Word Cloud Renderer
export default function WordCloud({ data }: any) {
  let chartData = [];
  if (data) {
    for (let key in data) {
      chartData.push({
        text: key,
        value: data[key],
        // name: "nn",
      });
    }
  }
  const width = window.screen.width;
  return (
    <div
      className={`word-cloud  ${i18next.language === "ar" && "change-align"}`}
    >
      <h2 className="word-cloud_title reset-align english">Word Cloud</h2>
      <Resizable style={resizeStyle}>
        <div style={{ width: "100%", height: "250px" }}>
          <ReactWordcloud
            options={{
              rotations: 0,
              fontSizes: width === 1920 ? [30, 40] : [10, 20],
              colors: ["#4727CB", "#C2C6CB", "#B8356C"],
              enableTooltip: true,
              padding: 4,
            }}
            words={chartData}
            // callbacks={callbacks}
          />
        </div>
      </Resizable>
    </div>
  );
}
