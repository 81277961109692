import AnalysisChart from "@components/Chart/Analysis";
import SocialSources from "@components/SocialSources";
import { CardLinks } from "@components/Card/Links";
import DashboardLayout from "@components/Layout";
import DocumentHeader from "@components/Modal";
import WordCloud from "@components/WordCloud";
import PieChart from "@components/Chart";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getSearchingResult } from "@redux/Actions/utils";
import Loader from "@components/Loader";

const Dashboard = ({ data }: any) => {
  return (
    <div className="row m-0 h-100 results">
      <div className="col-12">
        <DocumentHeader />
      </div>
      <div className="col-md-6 col-6 mb-3 pb-2">
        <div className="results-chart">
          {data?.sentiment_timeline && (
            <AnalysisChart
              labels={Object?.keys(data?.sentiment_timeline)}
              data={data?.sentiment_timeline}
            />
          )}
        </div>
      </div>

      <div className="col-md-3 col-6 mb-3 pb-2">
        <div className="results-chart">
          <PieChart chart={data?.aggregations?.sentiment} />
        </div>
      </div>

      <div className="col-md-3 col-6 mb-3 pb-2">
        <div className="results-chart">
          <WordCloud data={data?.word_cloud} />
        </div>
      </div>
      <div className="col-md-6 col-6 mb-3">
        <SocialSources
          hits={data?.top_social_media?.hits}
          total={data?.top_social_media?.total}
        />
      </div>
      <div className="col-md-6 col-6 mb-3">
        <CardLinks
          hits={data?.top_links?.hits}
          total={data?.top_links?.total}
        />
      </div>
    </div>
  );
};

const Loading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center p-5">
      <Loader color="golden" />
    </div>
  );
};

export default function ResultPage() {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const { searching, searchingInProgress, rtl, filterDropdown } = useSelector(
    (state: any) => state.utils
  );

  useEffect(() => {
    dispatch(
      getSearchingResult({ text: searchText, lang: rtl }, filterDropdown)
    );
  }, [rtl, searchText, filterDropdown]);

  return (
    <DashboardLayout onSearchChange={setSearchText}>
      {searchingInProgress ? <Loading /> : <Dashboard data={searching} />}
    </DashboardLayout>
  );
}
