import { getSocialIcon } from "@utils/mapping";
import "./index.scss";
import { useSelector } from "react-redux";

//Render Social Sources Inner Cards
export default function SocialCards({ data = {} }: any) {
  const { rtl } = useSelector((state: any) => state.utils);
  const direction = rtl === "ar" ? "change-align" : "";
  return (
    <div className="social-card">
      <span>{getSocialIcon("twitter")}</span>
      <p
        className={`m-0 ${direction}`}
        dangerouslySetInnerHTML={{
          __html: data?.highlight?.body?.text || data?.source?.body,
        }}
      ></p>
    </div>
  );
}
