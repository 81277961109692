export default function FileIcon() {
  return (
    <svg
      width={16}
      height={20}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0V5L7.99999 5.05441C7.99991 5.47848 7.99983 5.8906 8.04553 6.23052C8.09705 6.61372 8.22257 7.051 8.58579 7.41421C8.949 7.77743 9.38628 7.90295 9.76948 7.95447C10.1094 8.00017 10.5215 8.00009 10.9456 8.00001H10.9456H10.9456H10.9456L11 8H16V14C16 16.8284 16 18.2426 15.1213 19.1213C14.2426 20 12.8284 20 10 20H6C3.17157 20 1.75736 20 0.87868 19.1213C0 18.2426 0 16.8284 0 14V6C0 3.17157 0 1.75736 0.87868 0.87868C1.75736 0 3.17157 0 6 0H8ZM10 0.00462076V5C10 5.49967 10.0021 5.77383 10.0277 5.96402L10.0287 5.97131L10.036 5.97231C10.2262 5.99788 10.5003 6 11 6H15.9954C15.9852 5.58836 15.9525 5.31595 15.8478 5.06306C15.6955 4.69552 15.4065 4.40649 14.8284 3.82843L12.1716 1.17157C11.5935 0.593512 11.3045 0.304482 10.9369 0.152241C10.684 0.0474889 10.4116 0.0148133 10 0.00462076ZM4 11C4 10.4477 4.44772 10 5 10L11 10C11.5523 10 12 10.4477 12 11C12 11.5523 11.5523 12 11 12L5 12C4.44772 12 4 11.5523 4 11ZM5 14C4.44772 14 4 14.4477 4 15C4 15.5523 4.44772 16 5 16H9C9.55228 16 10 15.5523 10 15C10 14.4477 9.55229 14 9 14H5Z"
        fill="#2E1C7B"
        fillOpacity="0.3"
      />
    </svg>
  );
}
