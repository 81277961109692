import { useState } from "react";
import { useSelector } from "react-redux";

import CardModal from "@components/Modal/CardModal";
import { CardLinksProps, Hits } from "../interface";
import "./index.scss";

//Render Top Links Cards
export const CardLinks = ({ hits, total }: Partial<CardLinksProps>) => {
  const [modal, openModal] = useState<boolean>(false);
  const toggleModal = (state: boolean) => openModal(!state);
  const {
    rtl: lang,
    searching: { top_links },
  } = useSelector((state: any) => state.utils);
  const rtl = lang === "ar" && "change-align";
  let topLinks: Hits[] = [];
  if (hits?.length) {
    topLinks = hits?.length > 3 ? hits.slice(0, 3) : hits;
  }
  return (
    <>
      <div className="card-links w-100 h-100">
        <div className={`card-links-header`}>
          <h5 className="english">
            Top Links {total ? <span className="count">({total})</span> : null}
          </h5>
          <button
            className="dashboard-view-btn"
            onClick={() => toggleModal(modal)}
          >
            View More
          </button>
        </div>
        <div className={`card-links-body ${rtl}`}>
          {topLinks.map((item: Hits, index: number) => (
            <div className="subheading" key={`cardL-links-w${index}`}>
              <div className="subheading-header">
                <h6
                  dangerouslySetInnerHTML={{
                    __html: item?.highlight?.title?.text || item?.source?.title,
                  }}
                ></h6>
              </div>
              <div className="subheading-text">
                <p
                  className="m-0"
                  dangerouslySetInnerHTML={{
                    __html: item?.highlight?.body?.text || item?.source?.body,
                  }}
                ></p>
                <a href={item.source.url} target="_blank" rel="nofollow">
                  {item.source.url}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      {topLinks && top_links && (
        <CardModal
          title={"Links"}
          show={modal}
          sources={top_links}
          toggleModal={toggleModal}
          type={"links"}
        />
      )}
    </>
  );
};
