import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

//Date Filters Range Selector in Filters SideMenu
export default function DateRange(props: any) {
  const start = new Date();
  const end = new Date();
  start.setFullYear(2018);
  const initialStart = props.start ? new Date(props.start) : null;
  const initialEnd = props.end ? new Date(props.end) : null;
  const [startDate, setStartDate] = useState<any>(initialStart);
  const [endDate, setEndDate] = useState<any>(initialEnd);

  useEffect(() => {
    const initialStart = props.start ? new Date(props.start) : null;
    const initialEnd = props.end ? new Date(props.end) : null;
    setStartDate(initialStart);
    setEndDate(initialEnd);
  }, [props.start, props.end]);

  const dateChangeHandler = (state: any, date: any) => {
    if (state === "startDate") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
    if (props.onChange) {
      props.onChange(
        state === "startDate" ? date : startDate,
        state !== "startDate" ? date : endDate
      );
    }
  };

  return (
    <div className="datepicker">
      <DatePicker
        selected={startDate}
        onChange={(date: any) => dateChangeHandler("startDate", date)}
        // selectsStart={true}
        minDate={start}
        maxDate={end}
        showMonthDropdown
        showYearDropdown
        // showYearPicker
        value={startDate ? startDate.getFullYear() : ""}
      />
      <span>{props.to}</span>
      <DatePicker
        selected={endDate}
        onChange={(date: any) => dateChangeHandler("endDate", date)}
        // selectsEnd
        minDate={startDate || start}
        maxDate={end}
        showMonthDropdown
        showYearDropdown
        value={endDate ? endDate.getFullYear() : ""}
      />
    </div>
  );
}
