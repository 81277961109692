import "./index.scss";

//Perception Table in Modal
export default function PerceptionTable(props: any) {
  return (
    <div className="summary-table">
      <div className="report-table-sub_head">{props.title}</div>

      <div className="t-main">
        <div className="t-head">
          {props.headers.map((item: any, index: number) => (
            <div className="t-cell" key={`perception-head-${index}`}>
              {item}
            </div>
          ))}
        </div>

        <div className="t-body">
          {props?.body?.map((item: any, index: number) => (
            <div className="t-row" key={`perception-${index}`}>
              {item.map((data: any, i: number) => (
                <div className="t-cell" key={`perception-inner-${i}`}>
                  {data}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
