import { REPORT_SUCCESS, REPORT_PDF_SUCCESS } from "@constants/Types";

const initialState: any = {
  reportData: [],
  reportPDFData: [],
};

const reportReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case REPORT_SUCCESS: {
      return { ...state, reportData: action.payload };
    }
    case REPORT_PDF_SUCCESS: {
      return { ...state, reportPDFData: action.payload };
    }
    default:
      return state;
  }
};

export default reportReducer;
