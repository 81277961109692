import React from "react";
import "./_loader.scss";

//Shows Loader in results page until API returns data
const Loader = ({ color }: any) => {
  return (
    <div className={`spinnerDots ${color || ""}`}>
      <div className="dots1"></div>
      <div className="dots2"></div>
      <div className="dots3"></div>
    </div>
  );
};

export default Loader;
