import { combineReducers } from 'redux';
import auth from './authReducer';
import report from './report'
import utils from './utils'

export default combineReducers({
  auth,
  utils,
  report
});
