export default function HeartIcon() {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.3212 10.2901L9.36175 17.1455L9.36176 17.1455C9.63394 17.4105 9.77004 17.5431 9.93448 17.5648C9.97797 17.5705 10.022 17.5705 10.0655 17.5648C10.23 17.5431 10.3661 17.4105 10.6383 17.1455L17.6788 10.2901C19.5786 8.44023 19.8112 5.46803 18.2222 3.34519L17.8169 2.8037C15.8688 0.201201 11.8467 0.627206 10.487 3.58003C10.2955 3.99591 9.70449 3.99591 9.51299 3.58003C8.15335 0.627206 4.13117 0.201202 2.18313 2.8037L1.77781 3.34519C0.188817 5.46803 0.421372 8.44024 2.3212 10.2901Z"
        fill="#281964"
        stroke="white"
        strokeWidth="1.4"
      />
    </svg>
  );
}
