import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SignOut from "@assets/Icon/SignOut";
import StarIcon from "@assets/Icon/sidebar/StarIcon";
import UserIcon from "@assets/Icon/sidebar/UserIcon";
import HomeIcon from "@assets/Icon/sidebar/Home";
import side_logo from "@assets/img/mozn side-logo.png";

import { logout } from "@redux/Actions/auth";
import { useDispatch } from "react-redux";
import "./index.scss";

export default function Sidebar() {
  const [active, setActive] = useState(0);
  const dispatch = useDispatch();
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <>
      <div
        className="sidebar d-flex flex-column flex-shrink-0 english"
        style={{
          width: 49,
          transition: "all ease 0.5s",
        }}
      >
        <div className="center">
          <div className="sidebar-logo">
            <img src={side_logo} />
          </div>
        </div>
        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
          <li
            className={`nav-item ${active === 1 && "active"}`}
            onClick={() => setActive(1)}
          >
            <UserIcon />
          </li>
          <li
            className={`nav-item ${active === 2 && "active"}`}
            onClick={() => setActive(2)}
          >
            <Link to="/dashboard">
              <HomeIcon />
            </Link>
          </li>
          <li
            className={`nav-item ${active === 3 && "active"}`}
            onClick={() => setActive(3)}
          >
            <StarIcon />
          </li>
        </ul>
        <div className="dropdown">
          <div className="nav-item current-language">
            <span>{language}</span>
          </div>
          <button
            onClick={() => dispatch(logout())}
            className="center align-items-center pb-3 link-dark text-decoration-none"
          >
            <SignOut />
          </button>
        </div>
      </div>
    </>
  );
}
