import { reportApi, reportPDFApi } from "@network/network";

import { REPORT_SUCCESS, REPORT_PDF_SUCCESS } from "@constants/Types";

export const getReport: any = (data: any, cb: any) => (dispatch: any) => {
  return reportApi(data)
    .then((res: any) => {
      dispatch({ type: REPORT_SUCCESS, payload: res.data });
    })
    .catch((err: any) => {});
};

export const getPDFReport: any = (data: any, cb: any) => (dispatch: any) => {
  return reportPDFApi("blob")
    .then((res: any) => {
      const url = URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.download = "report.pdf";
      a.href = url;
      a.target = "_self";
      a.click();

      // dispatch({ type: REPORT_PDF_SUCCESS, payload: res.data });
    })
    .catch((err: any) => {});
};
