import { useState } from "react";
import { useSelector } from "react-redux";

import SocialCard from "@components/Card/Social";
import CardModal from "@components/Modal/CardModal";
import { CardLinksProps as CardProps, Hits } from "@components/Card/interface";
import "./index.scss";
// import { useTranslation } from "react-i18next";

export default function SocialSources({ hits, total }: CardProps) {
  // const { t } = useTranslation(["results", "common"]);
  const [modal, openModal] = useState<boolean>(false);
  const toggleModal = (state: boolean) => openModal(!state);
  const {
    searching: { top_social_media },
  } = useSelector((state: any) => state.utils);
  return (
    <>
      <div className="social-container h-100">
        <div className={`center-between social-container-header`}>
          <h2 className="english">
            Top Social Sources{" "}
            {total ? <span className="count">({total})</span> : null}{" "}
          </h2>
          <button
            className="dashboard-view-btn"
            onClick={() => toggleModal(modal)}
          >
            View More
          </button>
        </div>

        <div className={`row social-container-body`}>
          {hits?.length ? (
            hits?.slice(0, 4).map((item: Hits, index: number) => (
              <div
                className="col-6 social-container-col "
                key={`social-sources-${index}`}
              >
                <SocialCard data={item} />
              </div>
            ))
          ) : (
            <div className="d-flex justify-content-center align-items-center py-5">
              <strong>Not found</strong>
            </div>
          )}
        </div>
      </div>
      {total && top_social_media && (
        <CardModal
          title={"Top Social Sources"}
          show={modal}
          sources={top_social_media}
          toggleModal={toggleModal}
          type={"social"}
        />
      )}
    </>
  );
}
